<template>
  <Dialog :visible.sync="visible" :closable="false" class="dialog">
    <template #header>
      <div class="title">
        <p>Adicionar filiar</p>
        <Divider class="divider" />
        <p class="subtitle mt-10">Informe a filial que deseja incluir.</p>
      </div>
    </template>
    <div class="body">
      <div>
        <p class="labelInputText">
          Digite o CNPJ da filial.
          <i class="requiredItem">*</i>
        </p>
        <input
          type="text"
          class="inputStyle"
          ref="input"
          id="input"          
          :value="value"
          @input="$emit('input', $event.target.value)"
        />
      </div>
    </div>
    <template #footer>
      <div>
        <Button 
          class="cancelButton"
          label="Cancelar"
          icon="pi pi-check"
          autofocus
          @click="$emit('cancel')"
        />
        <Button
          label="Incluir e salvar filial"
          icon="pi pi-check"
          autofocus
          @click="$emit('confirm')"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Button from "primevue/button";
export default {
  props: {
    visible: Boolean,   
    isLoading: Boolean,
    value:String
  },
  components: {
    Dialog,
    Divider,  
    Button,
  },
  data() {
    return {
      itemSelected: null,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.dialog) {
  width: 50%;
  min-width: 697px;
  border-radius :12px;
  .p-dialog-header {
    display: flex;
    justify-content: center;
  }
  .title {
    margin-top: 10px;
    p {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    font-weight: 700;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 26px;
    width: 100%;
    .subtitle {
      font-weight: 400;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: rgba(107, 114, 128, 1);     
    }
  }
}
.p-dialog-mask {
  background-color: rgba($color: #303f49, $alpha: 0.6);
}

.p-dialog-content {
  display: flex;
  justify-content: center;
}

.requiredItem {
  color: rgba(180, 23, 58, 1);
}

.inputStyle {
  margin-top: 9px;
  border-radius: 1px;
  height: 38px;
  border: solid 1px rgba(229, 231, 235, 1);
  border-radius: 12px;
  padding-left: 10px;
  width: 529px;
}
.labelInputText {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(55, 65, 81, 1);
}
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cancelButton{
  background: white;
  border: solid 1px rgba(209, 213, 219, 1);
  color: black;  
}

button{
  border-radius: 8px;
}
</style>

